<template>
    <FormTemplate v-if="form" :form="form">
      <div class="form-group">
          <label class="label-for-xl">Name</label>
          <b-form-input
              type="text" class="form-control form-control-xl"
              placeholder="enter template name"
              v-model="form.data.name"
              :state="form.states.name"
          />
          <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
      </div>
      <div class="form-group">
          <label class="label-for-xl">Placeholder</label>
          <b-form-input
              type="text" class="form-control form-control-xl"
              placeholder="enter template placeholder"
              v-model="form.data.placeholder"
              :state="form.states.placeholder"
          />
          <b-form-invalid-feedback v-if="form.errors">{{ form.errors.placeholder }}</b-form-invalid-feedback>
      </div>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    props: ['template'],
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },

    methods: {

    },

    mounted(){
      let data = {name: "",placeholder: ""};
      if(this.template){
        data = {
          name: this.template.name,
          placeholder: this.template.placeholder,
        }
      }
      this.form = new Form(data,{
        onSubmit: (form) => {
          let method = 'post';
          let url = 'emails/template';

          if(this.template){
            method = 'put';
            url = `emails/template/${this.template.id}`;
          }

          return this.$api[method](url,{
            data: {
              name: form.get('name'),
              placeholder: form.get('placeholder')
            }
          }).then((res) => {
            if(!this.template){
              this.$router.push({name: "emails.templates.template", params: {templateId: res.data.id}})
            }
            return res;
          });
        },
        onSuccess: (form) => {
          if(!this.template){
            form.resetData();
          }
        }
      });

    }
};
</script>
